import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { allowedVideoType, maximumDocSize, maximumVideoSize } from '../Constants/Constant';
import { AlertDialogComponent } from '../shared-components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FileSizeValidationService {

  constructor(
    public dialog: MatDialog,
  ) { }

  isValidFile(file: File) : boolean {
    const fileExtension = file.name.split('.');
    if(allowedVideoType.includes(fileExtension[fileExtension.length - 1]) && file.size > maximumVideoSize) {
      this.showAlert('Maximum allowed video size is 800MB');
      return false
    }

    if(!allowedVideoType.includes(fileExtension[fileExtension.length - 1]) && file.size > maximumDocSize) {
      this.showAlert('Maximum allowed Doc size is 25MB');
      return false
    }

    return true;
  }

  showAlert(text) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '363px',
      height: '190px',
      backdropClass: 'blur',
      data: { alertText: text},
      panelClass: ['delete-dialog-css'],
      autoFocus: false
    })
  }
}
