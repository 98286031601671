export let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export let nameregex: RegExp = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
export let timeregex: RegExp = /^([01]\d|2[0-3]):?([0-5]\d)$/;
export let positiveNmuberRegex: RegExp = /^[1-9]\d*$/;
export let aspectRatio = '1';
export let userResizeToWidth = '300';
export let userResizeToHeight = '300';
export let logoResizeToWidth = '144';
export let logoResizeToHeight = '144';
export let dateFormat = 'dd-MM-yyyy';
export let timeFormat = 'HH:MM';
export let minDate = new Date();
export let paginatorPageSize = '10';
export let pageSizeOptions = [5, 10, 20, 100];
export let allowedImageType = ['jpeg', 'jpg', 'png', 'svg', 'bmp', 'tiff', 'tif'];
export let allowedVideoType = ['avi', 'ogg', 'mp4', '3gp'];
export let allowedAudioType = ['mp3'];
export let maximumVideoSize = 800 * 1024 * 1024;
export let maximumDocSize = 25 * 1024 * 1024;
export let maxLogoSize = 5 * 1024 * 1024;
export let FILE_TYPES = {
  IMAGE: 'image',
  PDF: 'pdf',
  VIDEO: 'video',
  AUDIO: 'audio',
  OTHER: 'other'
};
export let LIVE_CLASS_TABS = {
  TODAY: 'today',
  THIS_WEEK: 'this week',
  UPCOMING: 'upcoming',
  PAST: 'past'
};

export let WEEK_RANGE = {
  monday: Date,
  saturday: Date
};

export const STUDENT_ID = 'studentID';

export const UNARCHIVED_STATUS = 'active';
export const ARCHIVED_STATUS = 'archived';

export const ACTIVE_STATUS = 'active';
export const INACTIVE_STATUS = 'inactive';

export const SELECTED_SCHOOL_GROUP_KEY = 'schoolGroup';
export const SELF_ID_KEY = 'id';

export const ADMIN_ROLE = 'admin';
export const STUDENT_ROLE = 'student';
export const ROLE_TYPE = 'type';

export const ALL_ACTIVE_PARTICIPANT_PING = 'all_active_participants_ping';
export const NEW_PARTICIPANT_ADDED = 'new_participant_added';
export const PARTICIPANT_REMOVED = 'participant_removed';

export const HAND_RAISE = 'hand_raise';
export const HAND_LOWER = 'hand_lower';
export const ACCEPT_HAND_RAISE = 'accept_hand_raise'

export const CHAT = 'chat';

export const PARTICIPANTS_WINDOW = 'participants';
export const HANDRAISE_WINDOW = 'hand_raise_window';
export const CHAT_WINDOW = 'chat_window';

export const RELOAD_LIVE_CLASS_PAGE = 'reloadLiveClass';
export const RELOAD_LIVE_CLASS_PREV_PAGE = 'reloadLiveClassPrevPage';
export const DO_RELOAD = 'yes';
export const DO_NOT_RELOAD = 'no';

export let ANALYTICS_TABS = {
  OVERVIEW: 'Overview',
  SCHOOL: 'School',
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  SUBJECT: 'Subject'
}

export let LIVE_STREAMING_ERRORS = {
  NotAllowedError: 'NotAllowedError',
  WebSocketNotConnectedError: 'WebSocketNotConnected',
  TypeError: 'TypeError',
  WebSocketNotSupportedError: 'WebSocketNotSupported',
}

export let SUCCESS_CALLBACK_INFOS = {
  Initialized: 'initialized',
  JoinedTheRoom: 'joinedTheRoom',
  RoomInfo: 'roomInformation',
  NewStreamAvailable: 'newStreamAvailable',
  Pong: 'pong',
  PublishStarted: 'publish_started',
  PubilshFinished: 'publish_finished',
  PlayFinished: 'play_finished',
  DataChannelOpened: 'data_channel_opened',
  DataReceived: 'data_received',
  DataChannelClosed: 'data_channel_closed',
}

export const CONNECTION_ALIVE_CHECK_TIME = 5000;
export const STREAM_STATE_BROADCAST_TIME = 10000;
export const TEACHER_DEV_URL = 'testinstructor.shikshadaily.com';
export const STUDENT_DEV_URL = 'testlearner.shikshadaily.com';
export const SCHOOL_DEV_URL = 'testadmin.shikshadaily.com';
export const SUPER_ADMIN_DEV_URL = 'testsuperadmin.shikshadaily.com';
export const TEACHER_PROD_URL = 'instructor.shikshadaily.com';
export const STUDENT_PROD_URL = 'learner.shikshadaily.com';
export const SCHOOL_PROD_URL = 'admin.shikshadaily.com';
export const SUPER_ADMIN_PROD_URL = 'superadmin.shikshadaily.com';

export const MATHS_LAB_ADMIN_URL = 'mathslab-admin.thinklets.in';
export const MATHS_LAB_SUPER_ADMIN_URL = 'mathslab-super-admin.thinklets.in';
export const MATHS_LAB_TRAINER_URL = 'mathslab-trainer.thinklets.in';
export const MATHS_LAB_LEARNER_URL = 'mathslab.thinklets.in';


export const COURSE_CATEGORY_TITLE = 'course-category';
export const COURSES_LABEL = 'Courses';
export const COURSE_ACTIVITY = 'courseActivity';
export const COURSE_ACTIVITY_ROUTE = 'course-activity';
export const ACTIVITY_TYPES = {
  assignments: 'assignments',
  tests: 'tests',
  materials: 'materials',
  liveClasses: 'live_classes'
};
export const EDIT = 'edit';
export const ADMIN_THEME = 'admin';
export const PUBLISH_ACTION = 'PUBLISH';
export const UPDATE_ACTION = 'UPDATE';
export const SUBMITTED_STATUS = 'submitted';

export const TODAY = 'today';
export const UPCOMING = 'upcoming';
export const OVERDUE = 'overdue';
export const COMPLETED = 'completed';
