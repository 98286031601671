// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.thinklets.in/',
  antMediaPlayUrl: "wss://vc.uneecopscloud.com:5443/WebRTCAppEE/websocket",
  antMediaPublishUrl: "wss://vc.uneecopscloud.com//WebRTCAppEE/websocket",
  socketServerUrl: "wss://vc.uneecopscloud.com:8080",
  socketServerBaseUrl: "https://vc.uneecopscloud.com:8080",
  oauthClientID: "716979846987-9krfgg0bkng7lscn27el0h27g1c8gra8.apps.googleusercontent.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
